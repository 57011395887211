import React, { useCallback, useRef, useState } from "react";
import Tab from "@mui/material/Tab";
import ProfileTab from "./ProfileTab";
import ResetPasswordTab from "./ResetPasswordTab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { User } from "pages/users/types";
import { useDispatch } from "react-redux";
import { userDefaultValues } from "pages/users/Users";
import { constants } from "common/constants";
import DeviceAccessTab from "./DeviceAccessTab";
import AccessWindowDisplaySettings from "./AccessWindowDisplaySettings";
import { setUserData } from "redux/UserStateSlice";

const AccountSettingsPage: React.FC = () => {
  const [tabValue, setTabValue] = useState("1");
  const [userData, setUserDetails] = useState<User>(userDefaultValues);
  const dispatch = useDispatch();
  const isRunEffect = useRef(true);
  //Fetch user details from backend
  const getUserDetails = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const sessionUser = sessionStorage.getItem("user")
        ? sessionStorage.getItem("user")
        : localStorage.getItem("user");
      const userId = sessionUser
        ? JSON.parse(sessionUser as string).userId
        : "";
      const api = `users/${userId}`;
      const userDetailsResponse = await apiClient.get(api);
      const userDetails = userDetailsResponse.data.data as User;
      setUserDetails(userDetails);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (isRunEffect.current) {
      getUserDetails();
      isRunEffect.current = false;
    }
  }, [getUserDetails, isRunEffect]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const onCancelResetPassword = () => {
    setTabValue("1");
  };

  const onUpdatePassword = () => {
    setTabValue("1");
  };

  const onCancelAccessType = () => {
    setTabValue("1");
  };
  const onCancelAccessWindowDisplay = () => {
    setTabValue("1");
  };

  const onUpdateAccessType = (deviceAccessPreference: string) => {
    setUserDetails((prevState) => ({
      ...prevState,
      deviceAccessPreference,
    }));
    sessionStorage.getItem("user")
      ? sessionStorage.setItem(
          "user",
          JSON.stringify({ ...userData, deviceAccessPreference })
        )
      : localStorage.setItem(
          "user",
          JSON.stringify({ ...userData, deviceAccessPreference })
        );
    dispatch(
      setUserData({
        ...userData,
        isLoggedIn: true,
        deviceAccessPreference,
      })
    );
    setTabValue("1");
  };

  const onUpdateAccessWindowDisplay = (accessWindowDisplay: string) => {
    setUserDetails((prevState) => ({
      ...prevState,
      accessWindowDisplay,
    }));
    sessionStorage.getItem("user")
      ? sessionStorage.setItem(
          "user",
          JSON.stringify({ ...userData, accessWindowDisplay })
        )
      : localStorage.setItem(
          "user",
          JSON.stringify({ ...userData, accessWindowDisplay })
        );
    dispatch(
      setUserData({
        ...userData,
        isLoggedIn: true,
        accessWindowDisplay,
      })
    );
    setTabValue("1");
  };

  return (
    <TabContext value={tabValue}>
      <TabList
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="secondary"
      >
        <Tab label="Profile" value={"1"} />
        <Tab label="Security" value={"2"} />
        <Tab label="Connection Window Options" value={"3"} />
        <Tab label="Time Zone Display" value={"4"} />
      </TabList>
      <TabPanel value="1">
        <ProfileTab user={userData} getUpdatedUser={getUserDetails} />
      </TabPanel>
      <TabPanel value="2">
        <ResetPasswordTab
          user={userData}
          onCancel={onCancelResetPassword}
          onUpdatePassword={onUpdatePassword}
        />
      </TabPanel>
      <TabPanel value="3">
        <DeviceAccessTab
          user={userData}
          onCancel={onCancelAccessType}
          onUpdateAccessType={onUpdateAccessType}
        />
      </TabPanel>
      <TabPanel value="4">
        <AccessWindowDisplaySettings
          user={userData}
          onCancel={onCancelAccessWindowDisplay}
          onUpdateAccessWindowDisplay={onUpdateAccessWindowDisplay}
        />
      </TabPanel>
    </TabContext>
  );
};

export default AccountSettingsPage;
