import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { get } from "lodash";

import { constants } from "common/constants";
import TcTextField from "common/components/TcTextField";
import TcSelectWithStringArray from "common/components/TcSelectWithStringArray";
import FileUpload from "common/components/FileUpload";
import { AuthMethod, DomainRegistered, Protocol } from "common/enums";
import { AuthenticationProfile } from "../types";
import { generateUniqueName, isProfileNameExists } from "common/helpers/utils";
import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import TcPasswordTextField from "common/components/TcPasswordTextField";

type AddEditAuthProfileProps = {
  authProfile: AuthenticationProfile | null;
  domainRegistered: string;
  protocol: string;
  existingProfiles: AuthenticationProfile[];
  onSaveUpdateAuthProfile: (data: AuthenticationProfile) => void;
  onCancelSaveAuthProfile: () => void;
};

const AddEditAuthProfile: React.FC<AddEditAuthProfileProps> = ({
  authProfile,
  domainRegistered,
  existingProfiles,
  onSaveUpdateAuthProfile,
  onCancelSaveAuthProfile,
  protocol,
}) => {
  const defaultValue = authProfile
    ? { ...authProfile }
    : { id: existingProfiles.length + 1, name: "" };

  const methods = useForm<AuthenticationProfile>({
    mode: "onBlur",
    defaultValues: { ...defaultValue },
  });
  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { isValid, dirtyFields },
  } = methods;

  const dispatch = useDispatch();
  const authMethod = watch(`authenticationMethod`);
  const accessLevel = watch(`accessLevel`);

  const handleFileUploadClick = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      dispatch(setLoader({ loaderMessage: "Please wait", openLoader: true }));
      const filesResponse = await apiClient.post("uploads/key-file", formData);
      const responseData = filesResponse.data.data;

      dispatch(setLoader({ loaderMessage: "Please wait", openLoader: false }));
      setValue(`keyFile`, responseData.filePath, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } catch (error: any) {
      dispatch(setLoader({ loaderMessage: "Please wait", openLoader: false }));
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({ message: errorData, open: true, severity: "error" })
      );
    }
  };

  const setProfileName = useCallback(
    (accessLevel: string) => {
      const existingNames = existingProfiles.map(
        (profile: AuthenticationProfile) => profile.name
      );
      const uniqueName = generateUniqueName(
        `${protocol}-${accessLevel}`,
        existingNames
      );
      setValue(`name`, uniqueName, {
        shouldValidate: true,
        shouldTouch: true,
      });
    },
    [existingProfiles, protocol, setValue]
  );

  useEffect(() => {
    if (accessLevel && get(dirtyFields, "accessLevel")) {
      setProfileName(accessLevel);
    }
  }, [accessLevel, dirtyFields, setProfileName]);

  const getFontStyle = (name: string) => {
    return get(dirtyFields, name) ? "" : "italic";
  };

  const handleSave = handleSubmit(async (data: AuthenticationProfile) => {
    onSaveUpdateAuthProfile(data);
  });

  return (
    <>
      <Card key={1} variant="outlined" sx={{ marginTop: 1 }}>
        {authProfile ? (
          <CardHeader title="Update Authentication Profile" disableTypography />
        ) : (
          <CardHeader title="New Authentication Profile" disableTypography />
        )}
        <CardContent sx={{ padding: 1 }}>
          <FormProvider {...methods}>
            <form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TcSelectWithStringArray
                    name={`accessLevel`}
                    label="Access Level *"
                    rules={{
                      required: "Access level is required",
                    }}
                    options={constants.CONNECTION_PROFILE_ACCESS_PROFILES}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TcTextField
                    name={`name`}
                    label="Profile Name *"
                    inputProps={{
                      sx: {
                        fontStyle: getFontStyle(`name`),
                      },
                    }}
                    rules={{
                      required: "Name is required",
                      validate: {
                        nameExists: (value: string) =>
                          isProfileNameExists(value, existingProfiles)
                            ? "This rule name is already used"
                            : true,
                      },
                    }}
                  ></TcTextField>
                </Grid>
                {/* IF domain registered No then show fields */}
                {domainRegistered === DomainRegistered.NO && (
                  <>
                    {protocol === Protocol.SSH && (
                      <>
                        <Grid item xs={6}>
                          <TcSelectWithStringArray
                            name={`authenticationMethod`}
                            label="Authentication Method *"
                            rules={{
                              required: "Authentication Method is required",
                            }}
                            options={constants.CONNECTION_PROFILE_AUTH_METHODS}
                          />
                        </Grid>
                        {authMethod === AuthMethod.PASSWORD && (
                          <>
                            <Grid item xs={6}>
                              <TcTextField
                                name={`username`}
                                label="Username *"
                                rules={{
                                  required: "Username is required",
                                }}
                              ></TcTextField>
                            </Grid>
                            <TcPasswordTextField
                              name={`password`}
                              type="password"
                              passwordLabel="Password"
                              gridSize={6}
                              rules={{
                                required: "Password is required",
                              }}
                              label="Password *"
                            ></TcPasswordTextField>
                          </>
                        )}
                        {authMethod === AuthMethod.KEY && (
                          <>
                            <Grid item xs={12}>
                              <FileUpload
                                control={control}
                                rules={{ required: "File is required" }}
                                name={`keyFileName`}
                                onFileUpload={async (file: File) =>
                                  await handleFileUploadClick(file)
                                }
                              ></FileUpload>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    {(protocol.includes(Protocol.HTTP) ||
                      protocol === Protocol.RDP) && (
                      <>
                        <Grid item xs={6}>
                          <TcTextField
                            name={`username`}
                            rules={{
                              required: "Username is required",
                            }}
                            label="Username *"
                          ></TcTextField>
                        </Grid>
                        <TcPasswordTextField
                          name={`password`}
                          type="password"
                          passwordLabel="Password"
                          gridSize={6}
                          rules={{
                            required: "Password is required",
                          }}
                          label="Password *"
                        ></TcPasswordTextField>
                      </>
                    )}
                  </>
                )}
              </Grid>
              <Grid container spacing={2} paddingTop={1}>
                <Grid item>
                  <Button
                    size="small"
                    fullWidth
                    variant="contained"
                    color="info"
                    type="submit"
                    disabled={!isValid}
                    onClick={handleSave}
                  >
                    {"Save"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    fullWidth
                    variant="outlined"
                    color="info"
                    onClick={onCancelSaveAuthProfile}
                  >
                    {"Cancel"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </CardContent>
      </Card>
    </>
  );
};

export default AddEditAuthProfile;
