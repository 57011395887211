import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Grid,
  IconButton,
  CardContent,
  Card,
  Box,
  CardHeader,
  Tooltip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { get } from "lodash";

import {
  DeviceConnectionProfileForm,
  DeviceManufacturer,
  DeviceType,
  DeviceForm,
  AuthenticationProfile,
} from "../types";

import { constants } from "common/constants";
import TcTextField from "common/components/TcTextField";
import TcSelectWithStringArray from "common/components/TcSelectWithStringArray";
import {
  DefaultPort,
  DomainRegistered,
  HostnameType,
  Protocol,
} from "common/enums";
import { generateUniqueName, isProfileNameExists } from "common/helpers/utils";
import AuthProfilesTable from "./AuthProfilesTable";

type AddConnectionProfileProps = {
  device: DeviceForm;
  manufacturers: DeviceManufacturer[];
  deviceTypes: DeviceType[];
  profiles: DeviceConnectionProfileForm[];
  index: number;
  onDeleteProfile: () => void;
  onSaveProfile: () => void;
  currentProfile: DeviceConnectionProfileForm;
};

const AddConnectionProfile: React.FC<AddConnectionProfileProps> = ({
  device,
  manufacturers,
  deviceTypes,
  profiles,
  index,
  onDeleteProfile,
  onSaveProfile,
  currentProfile,
}) => {
  const {
    setValue,
    getValues,
    control,
    watch,
    formState: { errors, isValid, dirtyFields },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `connectionProfiles.${index}.urls`,
  });

  const [selectedProtocol, setSelectedProtocol] = useState(
    currentProfile?.protocol ?? ""
  );
  const onChangeProtocol = (value: string) => {
    setSelectedProtocol(value);
    setValue(`connectionProfiles.${index}.protocol`, value);
    setProtocolDefaultValues(value);
  };
  const hostnameType = watch(`connectionProfiles.${index}.hostnameType`);
  const [authenticationProfiles, setAuthenticationProfiles] = useState(
    getValues(`connectionProfiles.${index}.authenticationProfiles`) ?? []
  );

  const setProtocolDefaultValues = (selectedProtocol: string) => {
    const protocolPortMap: { [key: string]: number } = {
      RDP: DefaultPort.RDP,
      HTTP: DefaultPort.HTTP,
      SSH: DefaultPort.SSH,
      HTTPS: DefaultPort.HTTPS,
    };
    if (selectedProtocol) {
      const name =
        device.domainRegistered === DomainRegistered.YES
          ? `${selectedProtocol}-${DomainRegistered.REGISTERED}`
          : selectedProtocol;

      const existingNames =
        profiles
          ?.filter((_, prIndex) => index !== prIndex)
          .map((profile) => profile.name) ?? [];

      const uniqueName = generateUniqueName(name, existingNames);
      setValue(`connectionProfiles.${index}.name`, uniqueName, {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue(
        `connectionProfiles.${index}.port`,
        protocolPortMap[selectedProtocol],
        {
          shouldValidate: true,
          shouldTouch: true,
        }
      );
    }
  };

  useEffect(() => {
    if (
      selectedProtocol.includes(Protocol.HTTP) &&
      hostnameType === HostnameType.URL &&
      fields.length === 0
    ) {
      append("");
    }
  }, [selectedProtocol, hostnameType, append, fields.length]);

  const selectedType = deviceTypes.find(
    (deviceType) => deviceType.deviceTypeId === device.typeId
  );
  const selectedManufacturer = manufacturers.find(
    (manufacturer) => manufacturer.manufacturerId === device.manufacturerId
  );

  const getProtocolOptions = () => {
    if (selectedType?.name?.toLowerCase() === "gateway") {
      return selectedManufacturer?.name?.toLowerCase() === "operant"
        ? constants.CONNECTION_PROFILE_PROTOCOLS_GATEWAY
        : constants.CONNECTION_PROFILE_PROTOCOLS_GATEWAY_CONFIG;
    } else {
      return constants.CONNECTION_PROFILE_PROTOCOLS;
    }
  };

  const getFontStyle = (name: string) => {
    return get(dirtyFields, name) ? "" : "italic";
  };

  const onSaveUpdateAuthProfiles = (data: AuthenticationProfile[]) => {
    setAuthenticationProfiles(data);
    setValue(`connectionProfiles.${index}.authenticationProfiles`, data, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const handleSaveProfile = () => {
    onSaveProfile();
  };

  return (
    <Fragment>
      <Card variant="outlined" sx={{ margin: 1 }}>
        <CardHeader title="New Connection Profile" disableTypography />
        <CardContent sx={{ padding: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                variant="outlined"
                error={Boolean(errors[`connectionProfiles.${index}.protocol`])}
              >
                <InputLabel size="small">{"Protocol *"}</InputLabel>
                <Controller
                  name={`connectionProfiles.${index}.protocol`}
                  control={control}
                  rules={{ required: "Protocol is required" }}
                  render={({}) => (
                    <Select
                      data-testid={`connectionProfiles.${index}.protocol`}
                      label="Protocol *"
                      size="small"
                      value={selectedProtocol}
                      onChange={(event: SelectChangeEvent) =>
                        onChangeProtocol(event.target.value)
                      }
                    >
                      {getProtocolOptions().map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {Boolean(
                  get(errors, `connectionProfiles.${index}.protocol`)
                ) && (
                  <FormHelperText>
                    {get(errors, `connectionProfiles.${index}.protocol`)
                      ? (get(errors, `connectionProfiles.${index}.protocol`)
                          ?.message as unknown as string)
                      : ""}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name={`connectionProfiles.${index}.name`}
                label="Profile Name *"
                inputProps={{
                  sx: {
                    fontStyle: getFontStyle(`connectionProfiles.${index}.name`),
                  },
                }}
                rules={{
                  required: "Name is required",
                  validate: {
                    nameExists: (value: string) =>
                      isProfileNameExists(value, profiles)
                        ? "This rule name is already used"
                        : true,
                  },
                }}
              />
            </Grid>
            {selectedProtocol.includes(Protocol.HTTP) ? (
              <>
                <Grid item xs={6}>
                  <TcSelectWithStringArray
                    name={`connectionProfiles.${index}.hostnameType`}
                    label="Device IP or URL *"
                    rules={{ required: "This field is required" }}
                    options={constants.HOSTNAME_TYPES}
                  />
                </Grid>
                {hostnameType === HostnameType.IP ? (
                  <>
                    <Grid item xs={6}>
                      <TcTextField
                        name={`connectionProfiles.${index}.deviceIP`}
                        label="Device Local IP *"
                        inputProps={{
                          sx: {
                            fontStyle: getFontStyle(
                              `connectionProfiles.${index}.deviceIP`
                            ),
                          },
                        }}
                        rules={{ required: "IP is required" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TcTextField
                        name={`connectionProfiles.${index}.port`}
                        label="Service Port *"
                        inputProps={{
                          sx: {
                            fontStyle: getFontStyle(
                              `connectionProfiles.${index}.port`
                            ),
                          },
                        }}
                        rules={{ required: "Service port is required" }}
                        type="number"
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={6}>
                    {fields.map((field, fieldIndex) => (
                      <Box
                        key={field.id}
                        mb={2}
                        display="flex"
                        alignItems="center"
                      >
                        <TcTextField
                          name={`connectionProfiles.${index}.urls[${fieldIndex}]`}
                          fullWidth
                          label="URL *"
                          rules={{ required: "URL is required" }}
                        />
                        {fieldIndex !== 0 && (
                          <IconButton onClick={() => remove(fieldIndex)}>
                            <Tooltip title="Remove URL">
                              <RemoveIcon />
                            </Tooltip>
                          </IconButton>
                        )}
                        <IconButton onClick={() => append("")}>
                          <Tooltip title="Add More">
                            <AddIcon />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    ))}
                  </Grid>
                )}
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <TcTextField
                    name={`connectionProfiles.${index}.deviceIP`}
                    label="Device Local IP *"
                    inputProps={{
                      sx: {
                        fontStyle: getFontStyle(
                          `connectionProfiles.${index}.deviceIP`
                        ),
                      },
                    }}
                    rules={{ required: "IP is required" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TcTextField
                    name={`connectionProfiles.${index}.port`}
                    label="Service Port *"
                    inputProps={{
                      sx: {
                        fontStyle: getFontStyle(
                          `connectionProfiles.${index}.port`
                        ),
                      },
                    }}
                    rules={{ required: "Service port is required" }}
                    type="number"
                  />
                </Grid>
              </>
            )}
          </Grid>
          {device.domainRegistered === DomainRegistered.NO &&
            selectedProtocol !== Protocol.CONFIG && (
              <Grid container direction="column" spacing={1}>
                <AuthProfilesTable
                  domainRegistered={device.domainRegistered}
                  protocol={selectedProtocol}
                  existingProfiles={authenticationProfiles}
                  onSaveUpdateAuthProfiles={onSaveUpdateAuthProfiles}
                ></AuthProfilesTable>
              </Grid>
            )}

          <Grid container spacing={2} pt={1}>
            <Grid item>
              <Button
                size="small"
                type="submit"
                disabled={Object.keys(errors).length !== 0 || !isValid}
                onClick={handleSaveProfile}
                fullWidth
                variant="contained"
                color="info"
              >
                Save Profile
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onDeleteProfile}
              >
                Delete Profile
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default AddConnectionProfile;
