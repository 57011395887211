import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  MRT_TableContainer,
} from "material-react-table";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AddEditAuthProfile from "./AddEditAuthProfile";
import { AuthenticationProfile } from "../types";
import { AuthMethod } from "common/enums";

type AuthProfilesTableProps = {
  domainRegistered: string;
  protocol: string;
  existingProfiles: AuthenticationProfile[];
  onSaveUpdateAuthProfiles?: (data: AuthenticationProfile[]) => void;
};

const AuthProfilesTable: React.FC<AuthProfilesTableProps> = ({
  existingProfiles,
  domainRegistered,
  onSaveUpdateAuthProfiles,
  protocol,
}) => {
  const [data, setData] = useState<AuthenticationProfile[]>(
    () => existingProfiles
  );
  const [selectedRow, setSelectedRow] = useState<AuthenticationProfile | null>(
    null
  );
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleEditClick = (row: AuthenticationProfile) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
  };

  const handleEditCancelClick = () => {
    setSelectedRow(null);
    setOpenEditDialog(false);
  };

  const handleDelete = useCallback(
    (row: AuthenticationProfile) => {
      setSelectedRow(null);
      const updatedData = data.filter((profile) => profile.name !== row.name);
      onSaveUpdateAuthProfiles?.(updatedData);
    },
    [data, onSaveUpdateAuthProfiles]
  );

  const handleEditSave = (formData: AuthenticationProfile) => {
    let updatedData = [];
    if (selectedRow) {
      updatedData = data.map((profile) =>
        profile.id === formData.id ? formData : profile
      );
    } else {
      updatedData = [...data, formData];
    }
    setSelectedRow(null);
    setOpenEditDialog(false);
    onSaveUpdateAuthProfiles?.(updatedData);
  };

  const addAuthProfile = () => {
    setOpenEditDialog(true);
  };

  const columns = useMemo<MRT_ColumnDef<AuthenticationProfile>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "accessLevel",
        header: "Access Level",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data,
    enableRowOrdering: onSaveUpdateAuthProfiles ? true : false,
    enableSorting: false,
    enableRowActions: onSaveUpdateAuthProfiles ? true : false,
    positionActionsColumn: "last",
    enableExpandAll: false,
    muiTableBodyRowProps: () => ({
      sx: (theme) => ({
        backgroundColor: theme.palette.background.paper,
      }),
    }),
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
      sx: {
        transform: row.getIsExpanded() ? "rotate(180deg)" : "rotate(-90deg)",
        transition: "transform 0.2s",
      },
    }),
    renderDetailPanel: ({ row }) =>
      row.original ? (
        <Box
          sx={{
            display: "grid",
            margin: "auto",
            width: "100%",
          }}
        >
          {row.original.authenticationMethod && (
            <Grid item>
              <Typography>
                Authentication method: {row.original.authenticationMethod}
              </Typography>
            </Grid>
          )}
          {row.original.authenticationMethod === AuthMethod.KEY ? (
            <Grid item>
              <Typography>Key: {row.original.keyFileName}</Typography>
            </Grid>
          ) : (
            <>
              {row.original.username && (
                <Grid item>
                  <Typography>Username: {row.original.username}</Typography>
                </Grid>
              )}
              {row.original.password && (
                <Grid item>
                  <Typography>Password: ************</Typography>
                </Grid>
              )}
            </>
          )}
        </Box>
      ) : null,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const rows = [...data];
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          rows.splice(
            (hoveredRow as MRT_Row<AuthenticationProfile>).index,
            0,
            rows.splice(draggingRow.index, 1)[0]
          );
          onSaveUpdateAuthProfiles?.(rows);
        }
      },
    }),
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton
            color="info"
            onClick={() => handleEditClick(row.original)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="info" onClick={() => handleDelete(row.original)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  });

  useEffect(() => {
    setData(existingProfiles);
  }, [existingProfiles]);

  return (
    <>
      <Grid container paddingTop={2} marginTop={1}>
        {onSaveUpdateAuthProfiles ? (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="text" color="info" onClick={addAuthProfile}>
                Add Authentication Profile
              </Button>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography>
              <strong>Authentication Profiles</strong>
            </Typography>
          </Grid>
        )}
        <MRT_TableContainer table={table} />
      </Grid>
      {openEditDialog && (
        <Dialog open={openEditDialog} fullWidth maxWidth="md">
          <DialogContent>
            <AddEditAuthProfile
              authProfile={selectedRow}
              domainRegistered={domainRegistered}
              existingProfiles={
                selectedRow?.name
                  ? data.filter((profile) => profile.name !== selectedRow.name)
                  : data
              }
              onCancelSaveAuthProfile={handleEditCancelClick}
              onSaveUpdateAuthProfile={handleEditSave}
              protocol={protocol}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default AuthProfilesTable;
