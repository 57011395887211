import { constants } from "common/constants";
import {
  AccessWindowDisplayType,
  CompareValues,
  FilterDisplayOption,
  RecurrenceUnit,
  RuleStatus,
  RuleTemplate,
} from "common/enums";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  AuthenticationProfile,
  Device,
  DeviceConnectionProfileForm,
  DeviceForm,
  DeviceGroup,
  DevicesGroupFilterData,
} from "pages/devices/types";
import { Site } from "pages/sites/types";
import {
  DayInfo,
  DayType,
  RecurrenceMonth,
  Rule,
  ScheduleDays,
} from "pages/trust/types";
import { User, UserForm, UserGroup, UsersFilterData } from "pages/users/types";

export const hasAllElements = (
  inputArray: any[],
  otherArray: any[]
): boolean => {
  return inputArray.every((item) => otherArray.includes(item));
};

type ObjectType = {
  [key: string]: any;
};

export const hasMatchingObjects = (
  array1: string[],
  array2: ObjectType[],
  keyToCompare: string
): boolean => {
  return array1.some((obj1) =>
    array2.some(
      (obj2) => obj1.toLowerCase() === obj2[keyToCompare].toLowerCase()
    )
  );
};

export const hasMatchingElements = (
  array1: string[],
  array2: string[]
): boolean => {
  return array1.some((obj1) =>
    array2.some((obj2) => obj1.toLowerCase() === obj2.toLowerCase())
  );
};

export const isStringInArray = (
  array: string[],
  searchString: string
): boolean => {
  return array.some(
    (item) => item.toLowerCase() === searchString.toLowerCase()
  );
};

export const getAccessWindow = (
  startDate?: any,
  endDate?: any,
  fromTime?: string,
  toTime?: string
) => {
  const formatTime = (time?: string) =>
    time ? dayjs(time).format("LT") : undefined;
  const formatDate = (date?: any) =>
    date ? dayjs(date).format("ll") : undefined;

  const displayFromDate = formatDate(startDate);
  const displayToDate = formatDate(endDate);
  const displayFromTime = formatTime(fromTime);
  const displayToTime = formatTime(toTime);

  const dateRange =
    displayFromDate && displayToDate
      ? `${displayFromDate} to ${displayToDate}`
      : "";
  const timeRange =
    displayFromTime && displayToTime
      ? `${displayFromTime} – ${displayToTime}`
      : "";
  const accessWindow =
    dateRange && timeRange
      ? `${dateRange}<br/>${timeRange}`
      : dateRange || timeRange;
  return accessWindow;
};

export const getDateRangeAccessWindow = ({
  startDate,
  endDate,
  fromTime,
  toTime,
  daysTemplate,
  schedule,
}: {
  startDate?: any;
  endDate?: any;
  fromTime?: string;
  toTime?: string;
  daysTemplate?: RuleTemplate;
  schedule?: ScheduleDays;
}): string => {
  const formatTime = (time?: string): string | undefined =>
    time ? dayjs(time).format("LT") : undefined;

  const formatDate = (date?: any): string | undefined =>
    date ? dayjs(date).format("ll") : undefined;

  const displayFromDate = formatDate(startDate);
  const displayToDate = formatDate(endDate);
  const displayFromTime = formatTime(fromTime);
  const displayToTime = formatTime(toTime);

  const dateRange =
    displayFromDate && displayToDate
      ? displayFromDate === displayToDate
        ? `On ${displayFromDate}`
        : `${displayFromDate} to ${displayToDate}`
      : "";
  const timeRange =
    displayFromTime && displayToTime
      ? `${displayFromTime} – ${displayToTime}`
      : "";

  let template = "";

  if (daysTemplate) {
    if (daysTemplate !== RuleTemplate.CUSTOM) {
      displayFromDate === displayToDate
        ? (template = "")
        : (template = daysTemplate);
    } else if (schedule) {
      constants.DAYS.forEach((day) => {
        const ipDay = day as DayType;
        if (schedule[ipDay]?.isActive) {
          template = template ? `${template},${day}` : day;
        }
      });
    }
  }

  const range =
    dateRange && timeRange
      ? `${dateRange} ${timeRange}`
      : dateRange || timeRange;
  const accessWindow = template ? `${range} <br/> On ${template}` : range;

  return accessWindow;
};

export const getRepeatingAccessWindow = ({
  startDate,
  endDate,
  fromTime,
  toTime,
  schedule,
  recurrence,
  recurrenceUnit,
  recurrenceMonth,
}: {
  startDate?: any;
  endDate?: any;
  fromTime?: string;
  toTime?: string;
  schedule?: ScheduleDays;
  recurrence?: string;
  recurrenceUnit?: string;
  recurrenceMonth?: RecurrenceMonth;
}): string => {
  const formatTime = (time?: string): string | undefined =>
    time ? dayjs(time).format("LT") : undefined;

  const formatDate = (date?: any): string | undefined =>
    date ? dayjs(date).format("ll") : undefined;

  const displayFromDate = formatDate(startDate);
  const displayToDate = formatDate(endDate);
  const displayFromTime = formatTime(fromTime);
  const displayToTime = formatTime(toTime);

  const dateRange =
    displayFromDate && displayToDate
      ? displayFromDate === displayToDate
        ? `On ${displayFromDate}`
        : `${displayFromDate} to ${displayToDate}`
      : "";
  const timeRange =
    displayFromTime && displayToTime
      ? `${displayFromTime} – ${displayToTime}`
      : "";

  let template = "";

  if (recurrenceUnit) {
    const { WEEKS, MONTHS, DAYS, YEARS } = RecurrenceUnit;
    const { option } = recurrenceMonth ?? {};

    switch (recurrenceUnit) {
      case WEEKS:
        template = `Every ${
          recurrence ? (+recurrence > 1 ? recurrence : "") : ""
        } week${recurrence && +recurrence > 1 ? "s" : ""}`;
        const activeDays = constants.DAYS.filter(
          (day: DayType) => schedule?.[day]?.isActive
        );
        template += activeDays.length ? ` on ${activeDays.join(", ")}` : "";
        break;
      case MONTHS:
        const dateInfo = getDayInfo(startDate);
        template = `Every ${
          recurrence ? (+recurrence > 1 ? recurrence : "") : ""
        } month${recurrence && +recurrence > 1 ? "s" : ""}`;
        template +=
          option === "dayOfMonth"
            ? ` on day ${dateInfo.dayOfMonth || ""}`
            : ` on ${dateInfo.positionInMonth || ""}`;
        break;
      case DAYS:
        template =
          displayFromDate === displayToDate
            ? ""
            : `Every ${
                recurrence ? (+recurrence > 1 ? recurrence : "") : ""
              } day${recurrence && +recurrence > 1 ? "s" : ""}`;
        break;
      case YEARS:
        template = `Every ${
          recurrence ? (+recurrence > 1 ? recurrence : "") : ""
        } year${recurrence && +recurrence > 1 ? "s" : ""}`;
        break;
      default:
        break;
    }
  }

  const range =
    dateRange && timeRange
      ? `${dateRange} ${timeRange}`
      : dateRange || timeRange;
  const accessWindow = template ? `${range} <br/> ${template}` : range;

  return accessWindow;
};

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string) => {
  name = name.toUpperCase();
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children:
      name.length > 2
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : "SA",
  };
};

export const isAdminUser = () => {
  const user = sessionStorage.getItem("user")
    ? sessionStorage.getItem("user")
    : localStorage.getItem("user");
  if (user) {
    const loggedInUser = JSON.parse(user ?? "{}") as User;
    return (
      loggedInUser.roleName &&
      constants.ADMIN_ROLE_PATTERN.test(loggedInUser.roleName)
    );
  } else {
    return false;
  }
};

export const isEndUser = () => {
  const portalType = process.env.REACT_APP_PORTAL_TYPE?.toLowerCase();
  if (portalType === CompareValues.ENDUSER) {
    return true;
  }
  const user = sessionStorage.getItem("user")
    ? sessionStorage.getItem("user")
    : localStorage.getItem("user");
  if (user) {
    const loggedInUser = JSON.parse(user ?? "{}") as User;
    return (
      loggedInUser.roleName &&
      constants.END_USER_ROLE_PATTERN.test(loggedInUser.roleName)
    );
  } else {
    return false;
  }
};

export const filterDeviceGroup = (
  groups: DeviceGroup[],
  device: DeviceForm,
  accessMethods: string[]
) => {
  return groups.filter((group) => {
    const isAccessMethodValid =
      group.accessMethods && group.accessMethods.length > 0
        ? hasMatchingElements(accessMethods, group.accessMethods)
        : true;

    const isStatusValid =
      group.deviceStatuses && group.deviceStatuses.length > 0
        ? group.deviceStatuses.includes(device.status)
        : true;

    const isSiteValid =
      group.sites && group.sites.length > 0
        ? group.sites.includes(device.siteId)
        : true;

    const isTypeValid =
      group.deviceTypes && group.deviceTypes.length > 0
        ? group.deviceTypes.includes(device.typeId)
        : true;

    const isManufacturerValid =
      group.manufacturers && group.manufacturers.length > 0
        ? device.manufacturerId
          ? group.manufacturers.includes(device.manufacturerId)
          : false
        : true;
    return (
      isAccessMethodValid &&
      isStatusValid &&
      isManufacturerValid &&
      isTypeValid &&
      isSiteValid
    );
  });
};

export const filterUserGroups = (groups: UserGroup[], user: UserForm) => {
  return groups.filter((group) => {
    const isStatusValid = group.statuses
      ? group.statuses.includes(user.status)
      : true;

    const isCountryValid =
      group.countries && group.countries?.length > 0
        ? group.countries.includes(user.countryId ?? "")
        : true;

    const isStateValid =
      group.states && group.states?.length > 0
        ? group.states.includes(user.stateId ?? "")
        : true;

    const isRoleValid =
      group.roles && group.roles?.length > 0
        ? group.roles.includes(user.roleId ?? "")
        : true;

    const isCityValid =
      group.city && group.city.length > 0
        ? (user.city?.toLowerCase() ?? "").includes(group.city.toLowerCase())
        : true;

    const isOrgValid =
      group.organizations && group.organizations.length > 0
        ? group.organizations.includes(user.organizationId ?? "")
        : true;

    return (
      isOrgValid &&
      isCityValid &&
      isRoleValid &&
      isStateValid &&
      isCountryValid &&
      isStatusValid
    );
  });
};

export const getFilteredDeviceRows = (
  tableRows: Device[],
  filterValues: DevicesGroupFilterData
) => {
  const filteredDevices = tableRows.filter((device) => {
    const isGroupValid =
      filterValues.groups.length > 0
        ? hasMatchingObjects(
            filterValues.groups,
            device.groups ?? [],
            "groupId"
          )
        : true;

    const isAccessMethodsValid =
      filterValues.accessMethods.length > 0
        ? hasMatchingObjects(
            filterValues.accessMethods,
            device.accessMethods ?? [],
            "protocol"
          )
        : true;

    const isStatusValid =
      filterValues.statuses.length > 0
        ? filterValues.statuses.includes(device.status)
        : true;

    const isSiteValid =
      filterValues.sites.length > 0
        ? filterValues.sites.includes(device.siteId)
        : true;

    const isTypeValid =
      filterValues.types.length > 0
        ? filterValues.types.includes(device.typeId)
        : true;

    const isManufacturerValid =
      filterValues.manufacturers.length > 0
        ? device.manufacturerId
          ? filterValues.manufacturers.includes(device.manufacturerId)
          : false
        : true;
    const isOwnerValid =
      filterValues.owners.length > 0
        ? filterValues.owners.includes(device.ownerId ?? "")
        : true;

    const isOperatorValid =
      filterValues.operators.length > 0
        ? filterValues.operators.includes(device.operatorId ?? "")
        : true;
    return (
      isGroupValid &&
      isStatusValid &&
      isManufacturerValid &&
      isTypeValid &&
      isSiteValid &&
      isAccessMethodsValid &&
      isOwnerValid &&
      isOperatorValid
    );
  });
  return filteredDevices;
};

export const getFilteredUserRows = (
  tableRows: User[],
  filterValues: UsersFilterData
) => {
  const filteredUsers = tableRows.filter((user) => {
    const isGroupValid =
      filterValues.groups.length > 0
        ? hasMatchingObjects(filterValues.groups, user.groups ?? [], "groupId")
        : true;
    const isStatusValid =
      filterValues.statuses.length > 0
        ? filterValues.statuses.includes(user.status)
        : true;

    const isCountryValid =
      filterValues.selectedCountries.length > 0
        ? filterValues.selectedCountries.includes(user.countryId ?? "")
        : true;

    const isStateValid =
      filterValues.selectedStates.length > 0
        ? filterValues.selectedStates.includes(user.stateId ?? "")
        : true;

    const isRoleValid =
      filterValues.roles.length > 0
        ? filterValues.roles.includes(user.roleId ?? "")
        : true;

    const isCityValid =
      filterValues.city.length > 0
        ? (user.city?.toLowerCase() ?? "").includes(
            filterValues.city.toLowerCase()
          )
        : true;

    const isOrgValid =
      filterValues.organizations.length > 0
        ? filterValues.organizations.includes(
            user.organization?.organizationId ?? ""
          )
        : true;
    return (
      isGroupValid &&
      isOrgValid &&
      isCityValid &&
      isRoleValid &&
      isStateValid &&
      isCountryValid &&
      isStatusValid
    );
  });
  return filteredUsers;
};

type AccessLevel = "read" | "write" | "delete";
export const hasPermission = (
  featureId: string,
  accessLevel: AccessLevel
): boolean => {
  const sessionUser = sessionStorage.getItem("user")
    ? sessionStorage.getItem("user")
    : localStorage.getItem("user");
  const user = sessionUser && (JSON.parse(sessionUser ?? "{}") as User);
  if (!user) {
    return false;
  }
  //if user is Admin or super Admin then allow
  if (user.roleName && constants.ADMIN_ROLE_PATTERN.test(user.roleName)) {
    return true;
  }

  if (
    user.roleName &&
    constants.END_USER_ROLE_PATTERN.test(user.roleName) &&
    accessLevel === CompareValues.READ &&
    constants.END_USER_ACCESS_FEATURES.includes(featureId)
  ) {
    return true;
  }

  if (!user?.role?.permissions) {
    return false;
  }
  const permission = user.role.permissions.find(
    (perm) => perm.featureId === featureId
  );
  if (permission) {
    return permission[accessLevel] === true;
  }
  // Default to false if the featureId is not found
  return false;
};

export const hasAllPermissions = (featureId: string): boolean => {
  const sessionUser = sessionStorage.getItem("user")
    ? sessionStorage.getItem("user")
    : localStorage.getItem("user");
  const user = sessionUser && (JSON.parse(sessionUser ?? "{}") as User);
  if (!user) {
    return false;
  }
  //if user is Admin or super Admin then allow
  if (user.roleName && constants.ADMIN_ROLE_PATTERN.test(user.roleName)) {
    return true;
  }
  if (!user?.role?.permissions) {
    return false;
  }
  const permission = user.role.permissions.find(
    (perm) => perm.featureId === featureId
  );
  if (permission) {
    return (
      permission["read"] === true &&
      permission["write"] === true &&
      permission["delete"] === true
    );
  }
  // Default to false if the featureId is not found
  return false;
};

export const getAccessDeniedMenus = () => {
  //check if portal is for EndUser and restrict site settings
  const portalType = process.env.REACT_APP_PORTAL_TYPE?.toLowerCase();
  const portalRestrictSite =
    process.env.REACT_APP_PORTAL_RESTRICT_SITE?.toLowerCase();
  const sessionUser = sessionStorage.getItem("user")
    ? sessionStorage.getItem("user")
    : localStorage.getItem("user");
  const user = sessionUser && (JSON.parse(sessionUser ?? "{}") as User);
  if (!user) {
    return constants.ALL_MENUS;
  }

  if (portalType === CompareValues.ENDUSER) {
    if (portalRestrictSite === CompareValues.TRUE) {
      return [
        ...constants.END_USER_ACCESS_DENIED_MENUS,
        "sites.summary",
        "sites",
      ];
    }
    return constants.END_USER_ACCESS_DENIED_MENUS;
  }

  if (user.roleName && constants.ADMIN_ROLE_PATTERN.test(user.roleName)) {
    return [];
  }

  if (user.roleName && constants.END_USER_ROLE_PATTERN.test(user.roleName)) {
    if (portalRestrictSite === CompareValues.TRUE) {
      return [
        ...constants.END_USER_ACCESS_DENIED_MENUS,
        "sites.summary",
        "sites",
      ];
    }
    return constants.END_USER_ACCESS_DENIED_MENUS;
  }

  if (!user?.role?.routePermissions) {
    return constants.ALL_MENUS;
  }
  return user.role?.routePermissions
    .filter((item) => !item.hasAccess)
    .map((item) => item.featureId);
};

type FavoriteRowType = Site | Device | Rule | User | DeviceGroup | UserGroup;

export const filterRowsByFavorite = (rows: FavoriteRowType[]) => {
  const filteredRows = rows.filter((row: FavoriteRowType) => !!row.isFavorite);
  return filteredRows;
};

export const sortRowsByFavorite = (rows: FavoriteRowType[]) => {
  rows.sort((a, b) =>
    a.isFavorite === b.isFavorite ? 0 : a.isFavorite ? -1 : 1
  );
  return rows;
};

const sortRowsByRecent = (rows: FavoriteRowType[]) => {
  rows.sort((a, b) => {
    const dateA = a.lastAccessOn ?? new Date(0);
    const dateB = b.lastAccessOn ?? new Date(0);
    return new Date(dateB).getTime() - new Date(dateA).getTime();
  });
  return rows;
};

const sortRowsByCreatedOn = (rows: FavoriteRowType[]) => {
  rows.sort((a, b) => {
    const dateA = a.createdOn ?? a.modifiedOn ?? new Date(0);
    const dateB = b.createdOn ?? b.modifiedOn ?? new Date(0);
    return new Date(dateA).getTime() - new Date(dateB).getTime();
  });
  return rows;
};

export const sortRows = (
  rows: FavoriteRowType[],
  selectedValue?: FilterDisplayOption
) => {
  let records = [];
  if (selectedValue === FilterDisplayOption.FAVORITE) {
    //records = sortRowsByFavorite([...rows]);
    records = filterRowsByFavorite([...rows]);
  } else if (selectedValue === FilterDisplayOption.RECENT) {
    records = sortRowsByRecent([...rows]);
  } else {
    records = sortRowsByCreatedOn([...rows]);
  }
  return records;
};

export const sortDevicesByAccessStatus = (a: Device, b: Device) => {
  if (
    a.accessStatus === RuleStatus.ACCESSIBLE &&
    b.accessStatus === RuleStatus.IN_ACCESSIBLE
  ) {
    return -1;
  } else return 1;
};

export const getDayInfo = (selectedDate?: string): DayInfo => {
  if (selectedDate) {
    const inputDate = new Date(selectedDate);
    const dayOfMonth = inputDate.getDate();
    const dayOfWeek = inputDate.getDay();
    const getOrdinalSuffix = (number: number) => {
      const suffixes = ["th", "st", "nd", "rd"];
      const v = number % 100;
      return suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0];
    };
    const dayNames = constants.DAYS;
    const weekNumber = Math.ceil(dayOfMonth / 7);
    const dayNumberSuffix = getOrdinalSuffix(weekNumber);
    const positionInMonth = `${weekNumber}${dayNumberSuffix} ${dayNames[dayOfWeek]}`;

    return {
      dayOfMonth,
      positionInMonth,
      dayName: dayNames[dayOfWeek],
      weekNumber,
    };
  } else
    return {
      dayOfMonth: 0,
      positionInMonth: "",
      dayName: "",
      weekNumber: 0,
    };
};

dayjs.extend(utc);
dayjs.extend(timezone);

export const getTimezone = () => {
  return dayjs.tz.guess();
};

export const getAccessWindowTimezone = (deviceZone?: string) => {
  let zone = getTimezone();
  const user = getUser();
  const accessWindowDisplay = user?.accessWindowDisplay;
  switch (accessWindowDisplay) {
    case AccessWindowDisplayType.DEVICE:
      zone = deviceZone ? deviceZone : zone;
      break;

    case AccessWindowDisplayType.UTC:
      zone = "utc";
      break;
  }
  return zone;
};

export const getDeviceAccessEndTime = (
  inDate: string,
  ruleTimezone?: string,
  deviceTimezone?: string
) => {
  const zone = getAccessWindowTimezone(deviceTimezone);
  const currentDate = zone === "utc" ? dayjs.utc() : dayjs().tz(zone);
  const inDateTime = dayjs(inDate).tz(ruleTimezone);
  let endTimeDate = currentDate
    .set("hour", inDateTime.hour())
    .set("minute", inDateTime.minute());
  //if timeMs is less then current time then add one day
  // due to timezone offset the end time shifts to next day
  if (endTimeDate.isBefore(currentDate)) {
    endTimeDate = endTimeDate.add(1, "day");
  }
  const timeMS = endTimeDate.valueOf();
  return {
    seconds: Math.floor(timeMS / 1000),
    nanos: (timeMS % 1000) * 1000000,
  };
};

export const getAdminAccessTime = () => {
  const timeMS =
    new Date().getTime() + constants.GATE_END_TIME_HOURS * 60 * 60 * 1000;
  return {
    seconds: Math.floor(timeMS / 1000),
    nanos: (timeMS % 1000) * 1000000,
  };
};

export const getUniqueStrings = (arr: string[]): string[] => {
  return Array.from(new Set(arr));
};

export const isProfileNameExists = (
  name: string,
  profiles: DeviceConnectionProfileForm[] | AuthenticationProfile[]
) => {
  return profiles.some(
    (profile) => profile.name.toLowerCase() === name.toLowerCase()
  );
};

export const generateUniqueName = (
  name: string,
  profiles: string[]
): string => {
  let uniqueName = name;
  let counter = 2;
  while (profiles.includes(uniqueName)) {
    uniqueName = `${name}-${counter}`;
    counter++;
  }
  return uniqueName;
};

export const formatTimeInTimeZone = (timezone?: string) => {
  const now = dayjs();
  const timeInZone = now.tz(timezone ?? getTimezone());
  return timeInZone.format("hh.mmA z");
};

export const getUser = () => {
  const user = sessionStorage.getItem("user")
    ? sessionStorage.getItem("user")
    : localStorage.getItem("user");
  return user ? JSON.parse(user) : {};
};
