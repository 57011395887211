import React, { useEffect, useState, useCallback } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import { getAccessWindowTimezone } from "common/helpers/utils";

interface CountdownDialogProps {
  open: boolean;
  endTime: number;
  onClose: () => void;
  onTimeUp: () => void;
  handleDisconnectOkayClick: () => void;
  deviceTimezone?: string;
}

const CountdownDialog: React.FC<CountdownDialogProps> = ({
  open,
  endTime,
  onClose,
  onTimeUp,
  handleDisconnectOkayClick,
  deviceTimezone,
}) => {
  const [timeLeft, setTimeLeft] = useState(-1);
  const [isTimeUp, setIsTimeUp] = useState(false);

  useEffect(
    () =>
      setTimeLeft(
        Math.floor(
          endTime -
            dayjs().tz(getAccessWindowTimezone(deviceTimezone)).valueOf() / 1000
        )
      ),
    [endTime, deviceTimezone]
  );

  useEffect(() => {
    if (timeLeft === 0) {
      setIsTimeUp(true);
      onTimeUp();
      return;
    }

    const timerId = setTimeout(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);
    return () => clearTimeout(timerId);
  }, [onTimeUp, timeLeft]);

  const handleOkayClick = useCallback(() => {
    timeLeft === 0 ? onTimeUp() : onClose();
  }, [timeLeft, onClose, onTimeUp]);

  const formatTime = useCallback((seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    if (hours > 0) {
      return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
        remainingSeconds < 10 ? "0" : ""
      }${remainingSeconds}`;
    } else {
      return `${minutes}:${
        remainingSeconds < 10 ? "0" : ""
      }${remainingSeconds}`;
    }
  }, []);

  return (
    <Dialog open={open} disableEscapeKeyDown maxWidth="xs">
      <DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {!isTimeUp ? (
            <>
              <Typography variant="h6">
                Please note that your access window is scheduled to close in{" "}
                {formatTime(timeLeft)} minutes.
              </Typography>
            </>
          ) : (
            <Typography variant="h6">
              Your access time window has closed.
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          {isTimeUp ? (
            <Button
              onClick={handleDisconnectOkayClick}
              color="info"
              variant="contained"
            >
              OK
            </Button>
          ) : (
            <Button onClick={handleOkayClick} color="info" variant="contained">
              OK
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CountdownDialog;
