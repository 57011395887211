import React, { useCallback, useRef, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Guacamole from "guacamole-common-js";
import { DeviceDetails } from "pages/devices/types";
import apiClient from "common/apiClientAxios";
import { DeviceAccess } from "common/types";
import { useDispatch } from "react-redux";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { deviceAccessErrors } from "pages/devices/device-access-errors";
import { constants } from "common/constants";
import {
  formatTimeInTimeZone,
  getAdminAccessTime,
  getDeviceAccessEndTime,
  isEndUser,
} from "common/helpers/utils";
import { Protocol } from "common/enums";
import CountdownDialog from "common/components/CountDownDialog";

interface RemoteAccessSSHProps {
  open: boolean;
  onClose: (gateId?: number) => void;
  device: DeviceDetails;
  isHideDialog?: boolean;
}

const RemoteAccessSSH: React.FC<RemoteAccessSSHProps> = ({
  open,
  onClose,
  device,
  isHideDialog = false,
}) => {
  const isRunEffect = useRef(true);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [guacamoleClient, setGuacamoleClient] = useState<Guacamole.Client>();
  const [guacamoleKeyboard] = useState<Guacamole.Keyboard>(
    new Guacamole.Keyboard(document)
  );
  const [guacamoleClientState, setGuacamoleClientState] = useState(-1);
  const [guacamoleTunnelState, setGuacamoleTunnelState] = useState(-1);
  const [errorMessage, setErrorMessage] = useState("");
  const [gateId, setGateId] = useState(-1);
  const [inboundPort, setInboundPort] = useState(-1);
  const [webSocketURL, setWebSocketURL] = useState("");
  const accessEndTime = isEndUser()
    ? getDeviceAccessEndTime(
        device.rules?.[0].endTime ?? new Date().toISOString(),
        device.rules?.[0].timezone,
        device.timezone
      )
    : getAdminAccessTime();
  const [endTime, setEndTime] = useState(accessEndTime.seconds);
  const [countDownOpen, setCountDownOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [deviceLocalTime, setDeviceLocalTime] = useState(
    formatTimeInTimeZone(device.timezone)
  );

  const onReconnectSSH = async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: "Please wait connecting...",
          openLoader: true,
        })
      );
      const accessEndTime = isEndUser()
        ? getDeviceAccessEndTime(
            device.rules?.[0].endTime ?? new Date().toISOString(),
            device.rules?.[0].timezone,
            device.timezone
          )
        : getAdminAccessTime();
      setEndTime(accessEndTime.seconds);
      const apiUrl = `devices/${device.deviceId}/getReconnectToken`;
      const tokenResponse = await apiClient.post(apiUrl, {
        type: Protocol.SSH,
        connectionProfile: device.accessProfile,
      });
      const token = tokenResponse.data.data.token;
      //connect to guacamole server
      connectToDevice(token, gateId, inboundPort, webSocketURL);
      dispatch(
        setLoader({
          loaderMessage: "Please wait connecting...",
          openLoader: false,
        })
      );
      return token;
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: "Please wait connecting...",
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  };

  const closeConnection = useCallback(
    async (gateId?: number, inboundPort?: number) => {
      if (gateId !== -1) {
        try {
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: true,
            })
          );
          await apiClient.post(`devices/${device.deviceId}/closeConnection`, {
            siteId: device.siteId,
            gateId: gateId,
            inboundPort: inboundPort,
          });
          setGateId(-1);
          setInboundPort(-1);
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: false,
            })
          );
        } catch (error: any) {
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: false,
            })
          );
          const errorData =
            error.response?.data?.meta?.message || String(error.message);
          dispatch(
            setSnackbarToast({
              message: errorData,
              open: true,
              severity: "error",
            })
          );
        }
      }
    },
    [device.deviceId, device.siteId, dispatch]
  );

  const onDisconnect = async () => {
    if (guacamoleKeyboard && guacamoleKeyboard.onkeyup) {
      guacamoleKeyboard.onkeyup = null;
      guacamoleKeyboard.onkeydown = null;
    }
    guacamoleTunnelState === Guacamole.Tunnel.State.OPEN &&
      guacamoleClient?.disconnect();
    await closeConnection(gateId, inboundPort);
    window.setTimeout(() => onClose(), 100);
  };

  const disconnectFromCanvas = useCallback(
    async (inGateId?: number, inInboundPort?: number) => {
      await closeConnection(inGateId, inInboundPort);
      window.setTimeout(() => onClose(), 100);
    },
    [closeConnection, onClose]
  );

  const connectToDevice = useCallback(
    (
      token: string,
      inGateId: number,
      inInboundPort: number,
      webSocketBaseURL: string
    ) => {
      window.setTimeout(() => {
        const display = document.getElementById("SSHdisplay");
        const container = document.getElementById("container");
        const width = container?.clientWidth
          ? container?.clientWidth - constants.CANVAS_WIDTH_OFFSET
          : constants.DIALOG_CANVAS_WIDTH;
        const height = container?.clientHeight
          ? container?.clientHeight - constants.CANVAS_HEIGHT_OFFSET
          : constants.DIALOG_CANVAS_HEIGHT;
        let clientConnectedState = -1;
        if (display && token) {
          const guacTunnel = new Guacamole.WebSocketTunnel(
            `${webSocketBaseURL}/?token=${token}&width=${width}&height=${height}&`
          );
          const guac = new Guacamole.Client(guacTunnel);
          setGuacamoleClient(guac);
          const displayElement = guac.getDisplay().getElement();
          display?.appendChild(displayElement);

          // Mouse
          const mouse = new Guacamole.Mouse(guac.getDisplay().getElement());
          mouse.onEach(["mousedown", "mousemove", "mouseup"], () => {
            guac.sendMouseState(mouse.currentState);
          });
          // Keyboard
          if (guacamoleKeyboard) {
            guacamoleKeyboard.onkeydown = function (keysym) {
              guac.sendKeyEvent(1, keysym);
            };
            guacamoleKeyboard.onkeyup = function (keysym) {
              guac.sendKeyEvent(0, keysym);
            };
          }
          guac.onstatechange = async (state: Guacamole.Client.State) => {
            console.log("Guacamole Client State =", state);
            if (state === 3) {
              clientConnectedState = 3;
              setCountDownOpen(true);
            }
            //if client connection closed and canvas is displayed then remove canvas and close dialog
            if (state === 5) {
              if (clientConnectedState === 3) {
                window.setTimeout(async () => {
                  if (guacamoleKeyboard && guacamoleKeyboard.onkeyup) {
                    guacamoleKeyboard.onkeyup = null;
                    guacamoleKeyboard.onkeydown = null;
                    await disconnectFromCanvas(inGateId, inInboundPort);
                  }
                }, 200);
              }
              if (display.hasChildNodes()) {
                handleDisconnect();
              }
            } else {
              setGuacamoleClientState(state);
            }
          };
          guacTunnel.onstatechange = (state: Guacamole.Tunnel.State) => {
            console.log("Guacamole Tunnel State =", state);
            setGuacamoleTunnelState(state);
          };
          const handleDisconnect = async () => {
            if (display.hasChildNodes()) display.replaceChildren();
            guacTunnel?.state === Guacamole.Tunnel.State.OPEN &&
              guac?.disconnect();
            guacTunnel?.state === Guacamole.Tunnel.State.OPEN &&
              guacTunnel?.disconnect();
          };
          const handleError = (error: Guacamole.Status) => {
            clientConnectedState = -1;
            setErrorMessage(
              deviceAccessErrors[error.code] ?? "Something went wrong..."
            );
            handleDisconnect();
          };
          // Error handler
          guac.onerror = function (error) {
            handleError(error);
          };
          guacTunnel.onerror = function (error) {
            handleError(error);
          };
          // Connect
          guac.connect("test");
        }
      }, 1000);
    },
    [disconnectFromCanvas, guacamoleKeyboard]
  );

  const getToken = useCallback(
    async (connectionSettings: DeviceAccess) => {
      try {
        dispatch(
          setLoader({
            loaderMessage: "Please wait connecting...",
            openLoader: true,
          })
        );
        const apiUrl = `devices/${device.deviceId}/getToken`;
        const tokenResponse = await apiClient.post(apiUrl, {
          ...connectionSettings,
        });
        const tokenResponseData = tokenResponse.data.data;
        const token = tokenResponseData.token;
        setGateId(tokenResponseData.gateId ?? -1);
        setInboundPort(tokenResponseData.inboundPort ?? -1);
        setOpenDialog(true);
        dispatch(
          setLoader({
            loaderMessage: "Please wait connecting...",
            openLoader: false,
          })
        );
        //connect to guacamole server
        const webSocketBaseURL =
          process.env.REACT_APP_USE_LOCAL_WEB_SOCKET_URL?.toLowerCase() ===
          "yes"
            ? process.env.REACT_APP_WEB_SOCKET_URL ??
              constants.DEFAULT_WEB_SOCKET_URL
            : `${process.env.REACT_APP_WEB_SOCKET_PROTOCOL ?? "ws"}://${
                connectionSettings.siteName
              }-rc:${constants.DEFAULT_WEB_SOCKET_PORT}`;
        setWebSocketURL(webSocketBaseURL);
        connectToDevice(
          token,
          tokenResponseData.gateId ?? -1,
          tokenResponseData.inboundPort ?? -1,
          webSocketBaseURL
        );
        return token;
      } catch (error: any) {
        dispatch(
          setLoader({
            loaderMessage: "Please wait connecting...",
            openLoader: false,
          })
        );
        const errorData =
          error.response?.data?.meta?.message || String(error.message);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: "error",
          })
        );
        //if dialog then close
        if (!isHideDialog) {
          onClose();
        }
      }
    },
    [connectToDevice, device.deviceId, dispatch, isHideDialog, onClose]
  );

  React.useEffect(() => {
    if (isRunEffect.current) {
      isRunEffect.current = false;
      if (device.accessProfile) {
        getToken({
          connectionProfile: device.accessProfile,
          type: Protocol.SSH,
          endTime: accessEndTime,
          siteId: device.siteId,
          siteName: device.siteName,
        });
      }
    }
  }, [
    accessEndTime,
    device.accessProfile,
    device.siteId,
    getToken,
    device.siteName,
  ]);

  React.useEffect(() => {
    const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
      try {
        if (guacamoleKeyboard && guacamoleKeyboard.onkeyup) {
          guacamoleKeyboard.onkeyup = null;
          guacamoleKeyboard.onkeydown = null;
        }
        guacamoleTunnelState === Guacamole.Tunnel.State.OPEN &&
          guacamoleClient?.disconnect();
        await closeConnection(gateId, inboundPort);
      } catch (error) {
        console.error("Error while closing gate:", error);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [
    closeConnection,
    gateId,
    guacamoleClient,
    guacamoleKeyboard,
    inboundPort,
    guacamoleTunnelState,
  ]);

  const handleCloseCountDownPopup = () => {
    setCountDownOpen(false);
  };

  const handleTimeUp = () => {
    setCountDownOpen(true);
  };

  React.useEffect(() => {
    const timerId = setInterval(() => {
      setDeviceLocalTime(formatTimeInTimeZone(device.timezone));
    }, 60 * 1000);
    return () => clearInterval(timerId);
  }, [device.timezone]);

  return isHideDialog ? (
    <Card sx={{ overflow: "auto", minHeight: "100vh" }}>
      <CardHeader
        sx={{ paddingTop: 1, paddingBottom: 0 }}
        titleTypographyProps={{ textAlign: "center" }}
        title={
          <>
            <Typography component="span" sx={{ fontSize: 16 }}>
              {`Connect to device: '${device.name}' IP: ${device.ipAddress}`}
            </Typography>
            <br />
            <Typography component="span" sx={{ fontSize: 18 }}>
              {`Local Time at the Device is: `}
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                {deviceLocalTime}
              </Typography>
            </Typography>
          </>
        }
        action={
          <Button
            size="small"
            variant="contained"
            color="info"
            onClick={onDisconnect}
          >
            {"Close"}
          </Button>
        }
      />
      <CardContent sx={{ minHeight: "90vh" }} id="container">
        <Grid container justifyContent="center" alignItems="center">
          {/* Loading State */}
          {(guacamoleTunnelState === 0 ||
            guacamoleClientState === 1 ||
            guacamoleClientState === 2) &&
            guacamoleTunnelState !== 2 && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          {/* Error State */}
          {(guacamoleClientState === 5 || guacamoleTunnelState === 2) && (
            <Grid item>
              <Typography color="error" align="center">
                {errorMessage}
              </Typography>
              <Paper elevation={3} style={{ padding: "20px" }}>
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      size="small"
                      variant="outlined"
                      color="info"
                      onClick={onDisconnect}
                      id="cancel"
                    >
                      {"Cancel"}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="info"
                      onClick={onReconnectSSH}
                    >
                      {"ReConnect"}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12}>
            <div id="SSHdisplay"></div>
            <CountdownDialog
              open={countDownOpen}
              endTime={endTime}
              onClose={handleCloseCountDownPopup}
              handleDisconnectOkayClick={onDisconnect}
              deviceTimezone={device.timezone}
              onTimeUp={handleTimeUp}
            ></CountdownDialog>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ) : (
    <Dialog
      open={open && isOpenDialog}
      fullWidth
      PaperProps={{
        style: {
          minHeight: "90vh",
          minWidth: "70vw",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 1, pb: 0 }} textAlign={"center"}>
        <Typography component="span" sx={{ fontSize: 16 }}>
          {`Connect to device: '${device.name}' IP: ${device.ipAddress}`}
        </Typography>
        <br />
        <Typography component="span" sx={{ fontSize: 18 }}>
          {`Local Time at the Device is: `}
          <Typography component="span" sx={{ fontWeight: "bold" }}>
            {deviceLocalTime}
          </Typography>
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onDisconnect}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent id="container">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ minHeight: "100%" }}
        >
          {/* Loading State */}
          {(guacamoleTunnelState === 0 ||
            guacamoleClientState === 1 ||
            guacamoleClientState === 2) &&
            guacamoleTunnelState !== 2 && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          {/* Error State */}
          {(guacamoleClientState === 5 || guacamoleTunnelState === 2) && (
            <Grid item>
              <Typography color="error" align="center">
                {errorMessage}
              </Typography>
              <Paper elevation={3} style={{ padding: "20px" }}>
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      size="small"
                      variant="outlined"
                      color="info"
                      onClick={onDisconnect}
                      id="cancel"
                    >
                      {"Cancel"}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="info"
                      onClick={onReconnectSSH}
                    >
                      {"ReConnect"}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12}>
            <div id="SSHdisplay"></div>
            <CountdownDialog
              open={countDownOpen}
              endTime={endTime}
              handleDisconnectOkayClick={onDisconnect}
              onClose={handleCloseCountDownPopup}
              deviceTimezone={device.timezone}
              onTimeUp={handleTimeUp}
            ></CountdownDialog>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="info"
          onClick={onDisconnect}
        >
          {"Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RemoteAccessSSH;
