import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { User } from "pages/users/types";
import { useContentStyles } from "common/styles/useContentStyles";
import { constants } from "common/constants";
import { AccessWindowDisplayType } from "common/enums";
import { AccessWindowDisplayForm } from "../types";

type AccessWindowDisplaySettingsProps = {
  user: User;
  onUpdateAccessWindowDisplay: (accessWindowDisplay: string) => void;
  onCancel: () => void;
};

const AccessWindowDisplaySettings: React.FC<
  AccessWindowDisplaySettingsProps
> = ({ user, onUpdateAccessWindowDisplay, onCancel }) => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const methods = useForm<AccessWindowDisplayForm>({
    defaultValues: {
      accessWindowDisplay:
        user.accessWindowDisplay ?? AccessWindowDisplayType.BROWSER,
    },
    mode: "onBlur",
  });
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = methods;

  const handleSaveClick = handleSubmit(
    async (data: AccessWindowDisplayForm) => {
      try {
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: true,
          })
        );
        const userResponse = await apiClient.put(
          `/users/${user.userId}/access-window-display`,
          {
            ...data,
          }
        );
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          })
        );
        dispatch(
          setSnackbarToast({
            message: userResponse.data.meta.message,
            open: true,
            severity: "success",
          })
        );
        onUpdateAccessWindowDisplay(data.accessWindowDisplay);
      } catch (error: any) {
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          })
        );
        const errorData =
          error.response?.data?.meta?.message || String(error.message);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: "error",
          })
        );
        onCancel();
      }
    }
  );

  return (
    <Card variant="outlined">
      <CardHeader
        title="Access times displayed in:"
        disableTypography
        className={classes.cardHeader}
      ></CardHeader>
      <CardContent>
        <FormProvider {...methods}>
          <form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Controller
                    name="accessWindowDisplay"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Grid container direction="row">
                          <Grid item>
                            <FormControlLabel
                              value={AccessWindowDisplayType.BROWSER}
                              control={<Radio />}
                              label="User browser time"
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              value={AccessWindowDisplayType.DEVICE}
                              control={<Radio />}
                              label="Local Device Time"
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              value={AccessWindowDisplayType.UTC}
                              control={<Radio />}
                              label="UTC"
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <Button
                  size="small"
                  type="submit"
                  onClick={handleSaveClick}
                  fullWidth
                  variant="contained"
                  color="info"
                  id="save"
                  disabled={!isValid}
                >
                  {"Save"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export default AccessWindowDisplaySettings;
