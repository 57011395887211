import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import { constants } from "common/constants";
import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { CompareValues, IdentityAccessProvider } from "common/enums";

const EndUserLogin: React.FC = () => {
  const dispatch = useDispatch();
  const { username } = useParams();
  const navigate = useNavigate();
  const isRunEffect = useRef(true);

  const loginUser = useCallback(async () => {
    if (!username) {
      dispatch(
        setSnackbarToast({
          message: "Please select user",
          open: true,
          severity: "error",
        })
      );
      navigate("/portal/users/summary");
    }
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const loginResponse = await apiClient.post(`/users/loginAsEndUser`, {
        username,
      });

      const portalRestrictSite =
        process.env.REACT_APP_PORTAL_RESTRICT_SITE?.toLowerCase();
      sessionStorage.setItem(
        "access_token",
        loginResponse.data.data.accessToken
      );
      sessionStorage.setItem("user", JSON.stringify(loginResponse.data.data));
      sessionStorage.setItem("login_with", IdentityAccessProvider.CUSTOM);
      sessionStorage.setItem("login_as", "enduser");
      if (loginResponse.data?.data?.accessToken) {
        portalRestrictSite === CompareValues.TRUE
          ? navigate(constants.DEVICES_HOME_PAGE_PATH)
          : navigate(constants.HOME_PAGE_PATH);
      }
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    }
  }, [dispatch, navigate, username]);

  useEffect(() => {
    if (isRunEffect.current) {
      loginUser();
      isRunEffect.current = false;
    }
  }, [loginUser]);

  return <></>;
};

export default EndUserLogin;
