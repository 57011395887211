import React, { useState } from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  DeviceForm,
  DeviceType,
  DeviceManufacturer,
  DeviceConnectionProfileForm,
} from "../types";
import AddConnectionProfile from "./AddConnectionProfile";
import ConnectionProfile from "./ConnectionProfile";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

type ConnectionProfilesProps = {
  onSaveProfile: (data: DeviceForm) => void;
  device: DeviceForm;
  manufacturers: DeviceManufacturer[];
  deviceTypes: DeviceType[];
  onBackClick: () => void;
  onCancelClick: () => void;
  connectionProfiles?: DeviceConnectionProfileForm[];
};

const ConnectionProfiles: React.FC<ConnectionProfilesProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedAction, setSelectedAction] = useState("");
  const handleAddConnectionProfileClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelProfileClick = () => {
    setSelectedAction("");
    setAnchorEl(null);
  };
  const defaultValues: DeviceForm = {
    ...props.device,
    connectionProfiles: props.connectionProfiles ?? undefined,
  };

  const methods = useForm<DeviceForm>({
    defaultValues,
    mode: "all",
  });
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = methods;

  const {
    fields: connFields,
    append: appendConnForm,
    remove: removeConnForm,
  } = useFieldArray<any>({
    control,
    name: "connectionProfiles",
  });
  const [editMode, setEditMode] = useState<boolean[]>(
    connFields.map(() => false)
  );

  const handleCreateNewClick = () => {
    setSelectedAction("createNewProfile");
    setAnchorEl(null);
    appendConnForm({
      name: " ",
      protocol: "",
      deviceIP: props.device.ipAddress,
      port: " ",
      urls: [],
      authenticationProfiles: [],
    });
    setEditMode((prevEditMode) => {
      const newEditMode = [...prevEditMode];
      newEditMode[connFields.length] = true;
      return newEditMode;
    });
  };

  const handleDeleteConnProfile = (index: number) => {
    removeConnForm(index);
    setSelectedAction("");
  };

  const handleSaveConnProfile = (index: number) => {
    setEditMode((prevEditMode) => {
      const newEditMode = [...prevEditMode];
      newEditMode[index] = false;
      return newEditMode;
    });
    setSelectedAction("");
  };
  const handleEditConnProfile = (index: number) => {
    setEditMode((prevEditMode) => {
      const newEditMode = [...prevEditMode];
      newEditMode[index] = true;
      return newEditMode;
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onSaveDevice = handleSubmit(async (data: DeviceForm) => {
    props.onSaveProfile(data);
  });

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form noValidate>
          <Grid container spacing={2} paddingLeft={2}>
            <Grid item xs={12}>
              <Typography>
                <strong>Connection Profiles</strong>
              </Typography>
            </Grid>
            {connFields.map((field, index) => (
              <Grid item xs={12} key={field.id}>
                {editMode[index] ? (
                  <AddConnectionProfile
                    onDeleteProfile={() => handleDeleteConnProfile(index)}
                    onSaveProfile={() => handleSaveConnProfile(index)}
                    device={props.device}
                    deviceTypes={props.deviceTypes}
                    manufacturers={props.manufacturers}
                    index={index}
                    currentProfile={getValues(`connectionProfiles.${index}`)}
                    profiles={
                      getValues(`connectionProfiles`)?.filter(
                        (_pr: any, prIndex: number) => prIndex !== index
                      ) ?? []
                    }
                  ></AddConnectionProfile>
                ) : (
                  <ConnectionProfile
                    onClickEdit={() => handleEditConnProfile(index)}
                    onClickDelete={() => handleDeleteConnProfile(index)}
                    connectionProfile={getValues(`connectionProfiles.${index}`)}
                    domainRegistered={props.device.domainRegistered}
                    localIP={props.device.ipAddress}
                  ></ConnectionProfile>
                )}
              </Grid>
            ))}
            {selectedAction === "" && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleAddConnectionProfileClick}
                >
                  Add Connection Profile
                </Button>
              </Grid>
            )}
          </Grid>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Grid
              container
              justifyContent="center"
              spacing={1}
              padding={2}
              direction={"column"}
            >
              <Grid item>
                {" "}
                <Typography>
                  {" "}
                  <strong>New Connection Profile</strong>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleCreateNewClick}
                >
                  Create New Profile
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleCancelProfileClick}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Popover>
          <Grid container spacing={2} padding={2}>
            <Grid item>
              <Button
                size="small"
                type="submit"
                disabled={
                  connFields?.length === 0 ||
                  Object.keys(errors).length !== 0 ||
                  !isValid
                }
                onClick={onSaveDevice}
                fullWidth
                variant="contained"
                color="info"
              >
                {"Save Device"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                onClick={props.onBackClick}
                color="info"
              >
                {"Back"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                onClick={props.onCancelClick}
                color="info"
              >
                {"Cancel"}
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default ConnectionProfiles;
