import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Button,
  IconButton,
  Tooltip,
  FormControl,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  Divider,
  Theme,
  Box,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useFormContext } from "react-hook-form";
import { DateValidationError, TimeValidationError } from "@mui/x-date-pickers";
import Typography from "@mui/material/Typography";

import { constants } from "common/constants";
import TcSelectWithStringArray from "common/components/TcSelectWithStringArray";
import { AccessWindowType, RecurrenceUnit, RuleTemplate } from "common/enums";
import TcTextField from "common/components/TcTextField";
import { getDayInfo } from "common/helpers/utils";
import { DayInfo } from "../types";

type RuleScheduleFormProps = {
  open: boolean;
  onCancelCalendarModal: () => void;
  onAcceptCalendar: () => void;
};
const RuleScheduleForm: React.FC<RuleScheduleFormProps> = ({
  onCancelCalendarModal,
  open,
  onAcceptCalendar,
}) => {
  const { control, setValue, getValues, watch } = useFormContext();
  const getErrorMessage = (
    error: DateValidationError | TimeValidationError | null | "required"
  ) => {
    switch (error) {
      case "maxDate": {
        return "Date should be less than End Date";
      }
      case "minDate": {
        return "Date should be greater than Start Date";
      }
      case "invalidDate": {
        return "Your date is not valid";
      }
      case "maxTime": {
        return "Time should be less than End Time";
      }
      case "minTime": {
        return "Time should be greater than Start Time";
      }
      case "required": {
        return "This value is required";
      }
      default: {
        return "";
      }
    }
  };

  const [formErrors, setFormErrors] = useState<any>({});
  const setFormError = (
    key: string,
    value: DateValidationError | TimeValidationError | null | "required"
  ) => {
    setFormErrors((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const watchedTemplate = watch("daysTemplate");

  useEffect(() => {
    if (watchedTemplate) {
      if (watchedTemplate === RuleTemplate.ALL_DAYS) {
        constants.DAYS.forEach((day) => {
          setValue(`schedule.${day}.isActive`, true);

          setValue(`schedule.Saturday.startTime`, getValues(`startTime`));
          setValue(`schedule.Saturday.endTime`, getValues(`endTime`));
          setValue(`schedule.Sunday.startTime`, getValues(`startTime`));
          setValue(`schedule.Sunday.endTime`, getValues(`endTime`));
        });
      } else if (watchedTemplate === RuleTemplate.WEEK_DAYS) {
        constants.WEEK_DAYS.forEach((day) => {
          setValue(`schedule.${day}.isActive`, true);
        });
        setValue(`schedule.Saturday.isActive`, false);
        setValue(`schedule.Sunday.isActive`, false);

        setValue(`schedule.Saturday.startTime`, null);
        setValue(`schedule.Saturday.endTime`, null);
        setValue(`schedule.Sunday.startTime`, null);
        setValue(`schedule.Sunday.endTime`, null);
      }
    }
  }, [getValues, setValue, watchedTemplate]);

  const handleSelectIsActive = (day: string, checked: boolean) => {
    setValue(`schedule.${day}.isActive`, checked);
    if (checked) {
      getValues(`startTime`) &&
        setValue(`schedule.${day}.startTime`, getValues(`startTime`));
      getValues(`endTime`) &&
        setValue(`schedule.${day}.endTime`, getValues(`endTime`));
    } else {
      setValue(`schedule.${day}.startTime`, null);
      setValue(`schedule.${day}.endTime`, null);
    }
  };

  const clearTimeField = (fieldName: string) => {
    setValue(fieldName, null);
    if (fieldName === "recurrenceStartDate") {
      setDayInfo({
        dayName: "",
        dayOfMonth: 0,
        positionInMonth: "",
        weekNumber: 0,
      });
    }
  };

  const setDaysStartTime = (startTime: any) => {
    setValue(`startTime`, startTime, {
      shouldValidate: true,
      shouldDirty: true,
    });
    if (watchedTemplate === RuleTemplate.ALL_DAYS) {
      constants.DAYS.forEach((day) =>
        setValue(`schedule.${day}.startTime`, startTime)
      );
    } else if (watchedTemplate === RuleTemplate.WEEK_DAYS) {
      constants.WEEK_DAYS.forEach((day) =>
        setValue(`schedule.${day}.startTime`, startTime)
      );
      setValue(`schedule.Saturday.startTime`, null);
      setValue(`schedule.Sunday.startTime`, null);
    }
  };

  const setDaysEndTime = (endTime: any) => {
    setValue(`endTime`, endTime, { shouldValidate: true, shouldDirty: true });
    if (watchedTemplate === RuleTemplate.ALL_DAYS) {
      constants.DAYS.forEach((day) =>
        setValue(`schedule.${day}.endTime`, endTime)
      );
    } else if (watchedTemplate === RuleTemplate.WEEK_DAYS) {
      constants.WEEK_DAYS.forEach((day) =>
        setValue(`schedule.${day}.endTime`, endTime)
      );
      setValue(`schedule.Saturday.endTime`, null);
      setValue(`schedule.Sunday.endTime`, null);
    }
  };

  const [showMonthsOptions, setShowMonthsOptions] = useState(false);
  const [showWeeksOptions, setShowWeeksOptions] = useState(true);
  const watchedRecurrenceUnit = watch("recurrenceUnit");
  useEffect(() => {
    if (watchedRecurrenceUnit === RecurrenceUnit.WEEKS) {
      setShowWeeksOptions(true);
      setShowMonthsOptions(false);
    } else if (watchedRecurrenceUnit === RecurrenceUnit.MONTHS) {
      setShowMonthsOptions(true);
      setShowWeeksOptions(false);
    } else {
      setShowWeeksOptions(false);
      setShowMonthsOptions(false);
    }
  }, [watchedRecurrenceUnit]);

  const [accessWindowType, setAccessWindowType] = useState(
    getValues("accessWindowType") ?? AccessWindowType.DATE_RANGE
  );
  const handleTypeChange = (type: AccessWindowType) => {
    setValue("accessWindowType", type);
    setAccessWindowType(type);
  };
  const [dayInfo, setDayInfo] = useState<DayInfo>(
    getDayInfo(getValues("recurrenceStartDate"))
  );
  const onChangeRecurrenceStartDate = (startDate: any) => {
    setValue(`recurrenceStartDate`, startDate, {
      shouldValidate: true,
      shouldDirty: true,
    });
    const dayRecurrence = getDayInfo(startDate);
    setDayInfo(dayRecurrence);
  };

  const checkFormErrorOnAccept = () => {
    let hasError = false;
    setFormErrors({});
    if (accessWindowType === AccessWindowType.DATE_RANGE) {
      if (!getValues(`startDate`)) {
        setFormError("startDate", "required");
        hasError = true;
      }
      if (!getValues(`endDate`)) {
        setFormError("endDate", "required");
        hasError = true;
      }
      if (timeSelectionType !== RuleTemplate.FULL_DAY_TIME) {
        constants.DAYS.forEach((day) => {
          const isActive = getValues(`schedule.${day}.isActive`);
          const dayStartTime = getValues(`schedule.${day}.startTime`);
          const dayEndTime = getValues(`schedule.${day}.endTime`);
          if (isActive) {
            if (!dayStartTime) {
              setFormError(`${day}-startTime`, "required");
              hasError = true;
            }
            if (!dayEndTime) {
              setFormError(`${day}-endTime`, "required");
              hasError = true;
            }
          }
        });
      }
    } else if (accessWindowType === AccessWindowType.RECURRENCE) {
      if (!getValues(`recurrenceStartDate`)) {
        setFormError("recurrenceStartDate", "required");
        hasError = true;
      }
      if (!getValues(`recurrenceEndDate`)) {
        setFormError("recurrenceEndDate", "required");
        hasError = true;
      }
      if (recurrenceTimeSelectionType !== RuleTemplate.FULL_DAY_TIME) {
        if (!getValues(`recurrenceStartTime`)) {
          setFormError("recurrenceStartTime", "required");
          hasError = true;
        }
        if (!getValues(`recurrenceEndTime`)) {
          setFormError("recurrenceEndTime", "required");
          hasError = true;
        }
      }
    }
    if (!hasError) onAcceptCalendar();
  };

  const [timeSelectionType, setTimeSelectionType] = useState(
    getValues("timeSelectionType") ?? RuleTemplate.CUSTOM
  );
  const handleTimeSelectionTypeChange = (type: RuleTemplate) => {
    setValue("timeSelectionType", type);
    setTimeSelectionType(type);
    //clear all time fields on full day selection
    if (type === RuleTemplate.FULL_DAY_TIME) {
      clearTimeField(`startTime`);
      clearTimeField(`endTime`);
      constants.DAYS.forEach((day) => {
        clearTimeField(`schedule.${day}.startTime`);
        clearTimeField(`schedule.${day}.endTime`);
      });
    }
  };

  const [recurrenceTimeSelectionType, setRecurrenceTimeSelectionType] =
    useState(getValues("recurrenceTimeSelectionType") ?? RuleTemplate.CUSTOM);
  const handleRecurrenceTimeSelectionTypeChange = (type: RuleTemplate) => {
    setValue("recurrenceTimeSelectionType", type);
    setRecurrenceTimeSelectionType(type);
    //clear all time fields on full day selection
    if (type === RuleTemplate.FULL_DAY_TIME) {
      clearTimeField(`recurrenceStartTime`);
      clearTimeField(`recurrenceEndTime`);
    }
  };
  return (
    <React.Fragment>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle sx={{ p: 2 }}>
          Calendar
          <Box
            sx={{
              float: "right",
              paddingRight: 4,
              marginRight: 4,
              color: (theme) => theme.palette.info.main,
            }}
          >
            <Typography>
              Times displayed in: <strong>Local Time</strong>
            </Typography>
          </Box>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onCancelCalendarModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme: Theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent sx={{ minHeight: "500px" }}>
          <Grid container spacing={1}>
            {/* Select type */}
            <Grid item xs={12}>
              <Controller
                name="accessWindowType"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={accessWindowType}
                    onChange={(_event, value) =>
                      handleTypeChange(value as AccessWindowType)
                    }
                  >
                    <FormControlLabel
                      key={`accessWindowType-DATE_RANGE`}
                      value={AccessWindowType.DATE_RANGE}
                      control={<Radio size="small" />}
                      label={
                        <Typography variant="body1">Date Range</Typography>
                      }
                    />
                    <FormControlLabel
                      key={`accessWindowType-RECURRENCE`}
                      value={AccessWindowType.RECURRENCE}
                      control={<Radio size="small" />}
                      label={
                        <Typography variant="body1">
                          Regularly Repeating
                        </Typography>
                      }
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
          </Grid>
          {accessWindowType === AccessWindowType.DATE_RANGE && (
            <Grid container spacing={1} sx={{ pt: 2 }}>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={"startDate"}
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "50px",
                          },
                          "& .MuiOutlinedInput-root": {
                            height: "50px",
                          },
                          "& .MuiFormHelperText-root.Mui-disabled": {
                            color: (theme: Theme) => theme.palette.error.main,
                          },
                          "& .MuiOutlinedInput-root.Mui-disabled input": {
                            WebkitTextFillColor: (theme: Theme) =>
                              theme.palette.text.primary,
                          },
                          "& .MuiButtonBase-root": {
                            color: (theme: Theme) => theme.palette.text.primary,
                          },
                        }}
                        {...field}
                        label="Start Date"
                        onError={(newError) =>
                          setFormError("startDate", newError)
                        }
                        slotProps={{
                          textField: {
                            disabled: true,
                            helperText: getErrorMessage(
                              formErrors["startDate"]
                            ),
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={"endDate"}
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "50px",
                          },
                          "& .MuiOutlinedInput-root": {
                            height: "50px",
                          },
                          "& .MuiFormHelperText-root.Mui-disabled": {
                            color: (theme: Theme) => theme.palette.error.main,
                          },
                          "& .MuiOutlinedInput-root.Mui-disabled input": {
                            WebkitTextFillColor: (theme: Theme) =>
                              theme.palette.text.primary,
                          },
                          "& .MuiButtonBase-root": {
                            color: (theme: Theme) => theme.palette.text.primary,
                          },
                        }}
                        {...field}
                        label="End Date "
                        minDate={getValues("startDate")}
                        onError={(newError) =>
                          setFormError("endDate", newError)
                        }
                        slotProps={{
                          textField: {
                            disabled: true,
                            helperText: getErrorMessage(formErrors["endDate"]),
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <strong>Time selection:</strong>
                <Controller
                  name="timeSelectionType"
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      row
                      value={timeSelectionType}
                      onChange={(_event, value) =>
                        handleTimeSelectionTypeChange(value as RuleTemplate)
                      }
                    >
                      <FormControlLabel
                        key={`timeSelectionType-CUSTOM`}
                        value={RuleTemplate.CUSTOM}
                        control={<Radio size="small" />}
                        label={<Typography variant="body1">Custom</Typography>}
                      />
                      <FormControlLabel
                        key={`timeSelectionType-FULL_DAY_TIME`}
                        value={RuleTemplate.FULL_DAY_TIME}
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body1">Full Day</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>

              {/* Select template and start time and end time */}
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={3}>
                  <strong>Day</strong>
                  <TcSelectWithStringArray
                    name="daysTemplate"
                    label=""
                    defaultValue={RuleTemplate.ALL_DAYS}
                    options={constants.DAYS_TEMPLATE}
                  ></TcSelectWithStringArray>
                </Grid>
                <Grid item xs={9}>
                  <Grid
                    container
                    spacing={1}
                    border={1}
                    borderColor={(theme: Theme) => theme.palette.grey[500]}
                    borderRadius={1}
                    marginTop={0.5}
                    marginLeft={0.5}
                    paddingBottom={1}
                    sx={{ width: "88%" }}
                  >
                    <Grid item xs={6}>
                      <strong>Start Time</strong>
                      <Grid container alignItems="center" sx={{ width: "97%" }}>
                        <Grid item xs={9}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                              name={`startTime`}
                              control={control}
                              render={({ field }) => (
                                <TimePicker
                                  {...field}
                                  disabled={
                                    watchedTemplate === RuleTemplate.CUSTOM ||
                                    timeSelectionType ===
                                      RuleTemplate.FULL_DAY_TIME
                                  }
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: "40px",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      height: "40px",
                                    },
                                    "& .MuiFormHelperText-root.Mui-disabled": {
                                      color: (theme: Theme) =>
                                        theme.palette.error.main,
                                    },
                                    "& .MuiOutlinedInput-root.Mui-disabled input":
                                      {
                                        WebkitTextFillColor: (theme: Theme) =>
                                          theme.palette.text.primary,
                                      },
                                    "& .MuiButtonBase-root.Mui-disabled": {
                                      color: (theme: Theme) =>
                                        theme.palette.text.disabled,
                                    },
                                    "& .MuiButtonBase-root": {
                                      color: (theme: Theme) =>
                                        theme.palette.text.primary,
                                    },
                                  }}
                                  value={field.value || null}
                                  onChange={(date: any) => {
                                    setDaysStartTime(date);
                                  }}
                                  onError={(newError) =>
                                    setFormError("startTime", newError)
                                  }
                                  slotProps={{
                                    textField: {
                                      disabled: true,
                                      helperText: getErrorMessage(
                                        formErrors["startTime"]
                                      ),
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>

                        <Grid item>
                          <Tooltip title="Clear">
                            <IconButton
                              onClick={() => clearTimeField(`startTime`)}
                              sx={{ paddingLeft: "2px" }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ marginLeft: 4 }}>
                        <strong>End Time</strong>
                      </Box>
                      <Grid container justifyContent="flex-end">
                        <Grid item xs={9}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                              name={`endTime`}
                              control={control}
                              render={({ field }) => (
                                <TimePicker
                                  {...field}
                                  closeOnSelect={false}
                                  disabled={
                                    watchedTemplate === RuleTemplate.CUSTOM ||
                                    timeSelectionType ===
                                      RuleTemplate.FULL_DAY_TIME
                                  }
                                  minTime={getValues(`startTime`)}
                                  value={field.value || null}
                                  onChange={(date: any) => {
                                    setDaysEndTime(date);
                                  }}
                                  onError={(newError) =>
                                    setFormError("endTime", newError)
                                  }
                                  slotProps={{
                                    textField: {
                                      disabled: true,
                                      helperText: getErrorMessage(
                                        formErrors["endTime"]
                                      ),
                                    },
                                  }}
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: "40px",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      height: "40px",
                                    },
                                    "& .MuiFormHelperText-root.Mui-disabled": {
                                      color: (theme: Theme) =>
                                        theme.palette.error.main,
                                    },
                                    "& .MuiOutlinedInput-root.Mui-disabled input":
                                      {
                                        WebkitTextFillColor: (theme: Theme) =>
                                          theme.palette.text.primary,
                                      },
                                    "& .MuiButtonBase-root.Mui-disabled": {
                                      color: (theme: Theme) =>
                                        theme.palette.text.disabled,
                                    },
                                    "& .MuiButtonBase-root": {
                                      color: (theme: Theme) =>
                                        theme.palette.text.primary,
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Clear">
                            <IconButton
                              onClick={() => clearTimeField(`endTime`)}
                              sx={{ paddingLeft: "2px" }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {constants.DAYS.map((day, index) => (
                <Grid item xs={12} key={`${day}-${index}`}>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Controller
                            name={`schedule.${day}.isActive`}
                            control={control}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                                color="primary"
                                disabled={
                                  watchedTemplate !== RuleTemplate.CUSTOM
                                }
                                checked={field.value}
                                onChange={(_event, checked) =>
                                  handleSelectIsActive(day, checked)
                                }
                              />
                            )}
                          />
                        }
                        label={day}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                              name={`schedule.${day}.startTime`}
                              control={control}
                              render={({ field }) => (
                                <TimePicker
                                  {...field}
                                  disabled={
                                    timeSelectionType ===
                                    RuleTemplate.FULL_DAY_TIME
                                  }
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: "40px",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      height: "40px",
                                    },
                                    "& .MuiFormHelperText-root.Mui-disabled": {
                                      color: (theme: Theme) =>
                                        theme.palette.error.main,
                                    },
                                    "& .MuiOutlinedInput-root.Mui-disabled input":
                                      {
                                        WebkitTextFillColor: (theme: Theme) =>
                                          theme.palette.text.primary,
                                      },
                                    "& .MuiButtonBase-root.Mui-disabled": {
                                      color: (theme: Theme) =>
                                        theme.palette.text.disabled,
                                    },
                                    "& .MuiButtonBase-root": {
                                      color: (theme: Theme) =>
                                        theme.palette.text.primary,
                                    },
                                  }}
                                  onError={(newError) =>
                                    setFormError(`${day}-startTime`, newError)
                                  }
                                  slotProps={{
                                    textField: {
                                      disabled: true,
                                      helperText: getErrorMessage(
                                        formErrors[`${day}-startTime`]
                                      ),
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip title="Clear">
                            <IconButton
                              sx={{ paddingLeft: "0px", marginLeft: -0.5 }}
                              onClick={() =>
                                clearTimeField(`schedule.${day}.startTime`)
                              }
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                              name={`schedule.${day}.endTime`}
                              control={control}
                              render={({ field }) => (
                                <TimePicker
                                  {...field}
                                  disabled={
                                    timeSelectionType ===
                                    RuleTemplate.FULL_DAY_TIME
                                  }
                                  minTime={getValues(
                                    `schedule.${day}.startTime`
                                  )}
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: "40px",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      height: "40px",
                                    },
                                    "& .MuiFormHelperText-root.Mui-disabled": {
                                      color: (theme: Theme) =>
                                        theme.palette.error.main,
                                    },
                                    "& .MuiOutlinedInput-root.Mui-disabled input":
                                      {
                                        WebkitTextFillColor: (theme: Theme) =>
                                          theme.palette.text.primary,
                                      },
                                    "& .MuiButtonBase-root.Mui-disabled": {
                                      color: (theme: Theme) =>
                                        theme.palette.text.disabled,
                                    },
                                    "& .MuiButtonBase-root": {
                                      color: (theme: Theme) =>
                                        theme.palette.text.primary,
                                    },
                                  }}
                                  onError={(newError) =>
                                    setFormError(`${day}-endTime`, newError)
                                  }
                                  slotProps={{
                                    textField: {
                                      disabled: true,
                                      helperText: getErrorMessage(
                                        formErrors[`${day}-endTime`]
                                      ),
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Clear">
                            <IconButton
                              sx={{ paddingLeft: "0px", marginLeft: -0.5 }}
                              onClick={() =>
                                clearTimeField(`schedule.${day}.endTime`)
                              }
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
          {accessWindowType === AccessWindowType.RECURRENCE && (
            <Grid container alignItems={"center"} spacing={1} sx={{ pt: 2 }}>
              <Grid item xs={12} sx={{ marginTop: 2, paddingBottom: 1 }}>
                <Typography>
                  <strong>Custom Recurrence</strong>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={"recurrenceStartDate"}
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "50px",
                          },
                          "& .MuiOutlinedInput-root": {
                            height: "50px",
                          },
                          "& .MuiFormHelperText-root.Mui-disabled": {
                            color: (theme: Theme) => theme.palette.error.main,
                          },
                          "& .MuiOutlinedInput-root.Mui-disabled input": {
                            WebkitTextFillColor: (theme: Theme) =>
                              theme.palette.text.primary,
                          },
                          "& .MuiButtonBase-root": {
                            color: (theme: Theme) => theme.palette.text.primary,
                          },
                        }}
                        {...field}
                        label="Start Date"
                        value={field.value || null}
                        onChange={(date: any) => {
                          onChangeRecurrenceStartDate(date);
                        }}
                        onError={(newError) =>
                          setFormError("recurrenceStartDate", newError)
                        }
                        slotProps={{
                          textField: {
                            disabled: true,
                            helperText: getErrorMessage(
                              formErrors["recurrenceStartDate"]
                            ),
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2} sx={{ paddingLeft: "0px", marginLeft: -3 }}>
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => clearTimeField(`recurrenceStartDate`)}
                    sx={{ paddingLeft: "0px" }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={"recurrenceEndDate"}
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "50px",
                          },
                          "& .MuiOutlinedInput-root": {
                            height: "50px",
                          },
                          "& .MuiFormHelperText-root.Mui-disabled": {
                            color: (theme: Theme) => theme.palette.error.main,
                          },
                          "& .MuiOutlinedInput-root.Mui-disabled input": {
                            WebkitTextFillColor: (theme: Theme) =>
                              theme.palette.text.primary,
                          },
                          "& .MuiButtonBase-root": {
                            color: (theme: Theme) => theme.palette.text.primary,
                          },
                        }}
                        {...field}
                        label="End Date "
                        minDate={getValues("recurrenceStartDate")}
                        onError={(newError) =>
                          setFormError("recurrenceEndDate", newError)
                        }
                        slotProps={{
                          textField: {
                            disabled: true,
                            helperText: getErrorMessage(
                              formErrors["recurrenceEndDate"]
                            ),
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2} sx={{ paddingLeft: "0px", marginLeft: -3 }}>
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => clearTimeField(`recurrenceEndDate`)}
                    sx={{ paddingLeft: "0px" }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                <strong>Time selection:</strong>
              </Grid>
              <Grid item xs={10}>
                <Controller
                  name="recurrenceTimeSelectionType"
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      row
                      value={recurrenceTimeSelectionType}
                      onChange={(_event, value) =>
                        handleRecurrenceTimeSelectionTypeChange(
                          value as RuleTemplate
                        )
                      }
                    >
                      <FormControlLabel
                        key={`recurrenceTimeSelectionType-CUSTOM`}
                        value={RuleTemplate.CUSTOM}
                        control={<Radio size="small" />}
                        label={<Typography variant="body1">Custom</Typography>}
                      />
                      <FormControlLabel
                        key={`recurrenceTimeSelectionType-FULL_DAY_TIME`}
                        value={RuleTemplate.FULL_DAY_TIME}
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body1">Full Day</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={`recurrenceStartTime`}
                    control={control}
                    render={({ field }) => (
                      <TimePicker
                        {...field}
                        label="Start Time"
                        disabled={
                          recurrenceTimeSelectionType ===
                          RuleTemplate.FULL_DAY_TIME
                        }
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "50px",
                          },
                          "& .MuiOutlinedInput-root": {
                            height: "50px",
                          },
                          "& .MuiFormHelperText-root.Mui-disabled": {
                            color: (theme: Theme) => theme.palette.error.main,
                          },
                          "& .MuiOutlinedInput-root.Mui-disabled input": {
                            WebkitTextFillColor: (theme: Theme) =>
                              theme.palette.text.primary,
                          },
                          "& .MuiButtonBase-root.Mui-disabled": {
                            color: (theme: Theme) =>
                              theme.palette.text.disabled,
                          },
                          "& .MuiButtonBase-root": {
                            color: (theme: Theme) => theme.palette.text.primary,
                          },
                        }}
                        onError={(newError) =>
                          setFormError("recurrenceStartTime", newError)
                        }
                        slotProps={{
                          textField: {
                            disabled: true,
                            helperText: getErrorMessage(
                              formErrors["recurrenceStartTime"]
                            ),
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2} sx={{ paddingLeft: "0px", marginLeft: -3 }}>
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => clearTimeField(`recurrenceStartTime`)}
                    sx={{ paddingLeft: "0px" }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={`recurrenceEndTime`}
                    control={control}
                    render={({ field }) => (
                      <TimePicker
                        {...field}
                        disabled={
                          recurrenceTimeSelectionType ===
                          RuleTemplate.FULL_DAY_TIME
                        }
                        label="End Time"
                        closeOnSelect={false}
                        minTime={getValues(`recurrenceStartTime`)}
                        onError={(newError) =>
                          setFormError("recurrenceEndTime", newError)
                        }
                        slotProps={{
                          textField: {
                            disabled: true,
                            helperText: getErrorMessage(
                              formErrors["recurrenceEndTime"]
                            ),
                          },
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "50px",
                          },
                          "& .MuiOutlinedInput-root": {
                            height: "50px",
                          },
                          "& .MuiFormHelperText-root.Mui-disabled": {
                            color: (theme: Theme) => theme.palette.error.main,
                          },
                          "& .MuiOutlinedInput-root.Mui-disabled input": {
                            WebkitTextFillColor: (theme: Theme) =>
                              theme.palette.text.primary,
                          },
                          "& .MuiButtonBase-root.Mui-disabled": {
                            color: (theme: Theme) =>
                              theme.palette.text.disabled,
                          },
                          "& .MuiButtonBase-root": {
                            color: (theme: Theme) => theme.palette.text.primary,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2} sx={{ paddingLeft: "0px", marginLeft: -3 }}>
                <Tooltip title="Clear">
                  <IconButton
                    onClick={() => clearTimeField(`recurrenceEndTime`)}
                    sx={{ paddingLeft: "0px" }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={5}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>Repeat Every</Grid>
                  <Grid item xs={3}>
                    <TcTextField
                      name="recurrence"
                      label=""
                      type="number"
                      defaultValue={"1"}
                      rules={{
                        min: 1,
                        pattern: {
                          value: constants.REPEAT_EVENT_PATTERN,
                          message: "Invalid value",
                        },
                      }}
                    ></TcTextField>
                  </Grid>
                  <Grid item>
                    <TcSelectWithStringArray
                      name="recurrenceUnit"
                      label=""
                      defaultValue={"Days"}
                      options={[
                        RecurrenceUnit.DAYS,
                        RecurrenceUnit.WEEKS,
                        RecurrenceUnit.MONTHS,
                        RecurrenceUnit.YEARS,
                      ]}
                    ></TcSelectWithStringArray>
                  </Grid>
                </Grid>
              </Grid>
              {/* show weeks Recurrence options */}
              {showWeeksOptions && (
                <Grid item xs={12}>
                  {constants.DAYS.map((day) => (
                    <FormControlLabel
                      key={`weeks-options-${day}`}
                      control={
                        <Controller
                          key={`recurrenceWeekSchedule.${day}`}
                          name={`recurrenceWeekSchedule.${day}.isActive`}
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              color="primary"
                              checked={field.value}
                            />
                          )}
                        />
                      }
                      label={day}
                    />
                  ))}
                </Grid>
              )}
              {/* show months Recurrence options */}
              {showMonthsOptions && (
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <Controller
                          name="recurrenceMonth.option"
                          control={control}
                          render={({ field }) => (
                            <Select
                              labelId="day-name-label"
                              {...field}
                              size="small"
                            >
                              <MenuItem value="dayOfMonth">
                                {`Monthly on day ${dayInfo.dayOfMonth}`}
                              </MenuItem>
                              <MenuItem value="positionInMonth">
                                {`Monthly on ${dayInfo.positionInMonth}`}
                              </MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ pb: 2, pl: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Button
                size="small"
                type="button"
                onClick={checkFormErrorOnAccept}
                fullWidth
                variant="contained"
                color="info"
              >
                {"Accept"}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                type="button"
                variant="outlined"
                color="info"
                onClick={onCancelCalendarModal}
              >
                {"Cancel"}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default RuleScheduleForm;
